import React, { useState, useEffect, dispatch } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
	retrieveCompounds,
	findCompoundById,
	findCompoundByIndex,
	deleteAllCompounds,
} from "../actions/compounds";
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCardText } from 'mdb-react-ui-kit';

const CompoundsList = () => {
	const [currentCompound, setCurrentCompound] = useState(null);
	const [currentIndex, setCurrentIndex] = useState(-1);
	const [searchTitle, setSearchTitle] = useState("");
	const compounds = useSelector(state => state.compounds);
	const dispatch = useDispatch();
	const [viewMode, setViewMode] = useState(false);

	useEffect(() => {
		dispatch(retrieveCompounds());
	}, []);

	const onChangeSearchTitle = e => {
		const searchTitle = e.target.value;
		setSearchTitle(searchTitle);
	};

	const refreshData = () => {
		setCurrentCompound(null);
		setCurrentIndex(-1);
	};

	const setActiveCompound = (compound, index) => {
		setCurrentCompound(compound);
		setCurrentIndex(index);
	};

	const removeAllCompounds = () => {
		dispatch(deleteAllCompounds())
		.then(response => {
			console.log(response);
			refreshData();
		})
		.catch(err => {
			console.log(err);
		});
	};

	const findByTitle = () => {
		refreshData();
		dispatch(findCompoundByIndex(searchTitle));
	};

	const downloadMol2 = () => {
		const element = JSONtoMOL2();
		const url = window.URL.createObjectURL(new Blob([element]));
		const link = document.createElement('a');
		const filename = currentCompound.comp_material + ".mol2";

		link.href = url;
		link.setAttribute(
			'download',
			filename
		);

		document.body.appendChild(link);
		link.click();
		link.parentNode.removeChild(link);
	};

	//Method to change the JSONB to a MOL2 FIle
	// const JSONtoMOL2 = () => {
	// 	const obj = JSON.parse(currentCompound.comp_mol2);
	//
	// 	console.log(obj.mol2.atom);
	//
	// 	//Mapping object values to key value pairs
	// 	const moleculeMap= new Map(Object.entries(obj.mol2.molecule));
	// 	const atomMap=new Map(Object.entries(obj.mol2.atom));
	// 	const bondMap= new Map(Object.entries(obj.mol2.bond));
	//
	// 	//Concatenating molecule information into string
	// 	var mol2FileString= "@<TRIPOS>MOLECULE\n";
	// 	mol2FileString+= moleculeMap.get("mol_name")+"\n ";
	// 	mol2FileString+= moleculeMap.get("num_atoms")+" "+
	// 		moleculeMap.get("num_bonds")+" "+
	// 		moleculeMap.get("num_subst")+" "+
	// 		moleculeMap.get("num_feat")+" "+
	// 		moleculeMap.get("num_sets")+"\n";
	//
	// 	mol2FileString+=moleculeMap.get("mol_type")+"\n";
	//
	// 	mol2FileString+=moleculeMap.get("charge_type")+"\n";
	//
	// 	if(moleculeMap.get("status_bits")!==""){
	// 		mol2FileString+=moleculeMap.get("status_bits")+"\n";
	// 	}
	//
	// 	mol2FileString+=moleculeMap.get("mol_comment")+"\n";
	//
	// 	//Concatenating atom information into string
	// 	mol2FileString+= "@<TRIPOS>ATOM\n";
	// 	const numPaddingATOM= formatSpacing(atomMap);
	//
	// 	atomMap.forEach((value,key)=>{
	// 		const atomEntryMap=new Map(Object.entries(value));
	//
	// 		mol2FileString+=atomEntryMap.get("atom_id").padStart(numPaddingATOM," ")+
	// 			atomEntryMap.get("atom_name").padStart(numPaddingATOM," ")+
	// 			atomEntryMap.get("x").padStart(numPaddingATOM," ")+
	// 			atomEntryMap.get("y").padStart(numPaddingATOM," ")+
	// 			atomEntryMap.get("z").padStart(numPaddingATOM," ")+
	// 			atomEntryMap.get("atom_type").padStart(numPaddingATOM," ")+
	// 			atomEntryMap.get("subst_id").padStart(numPaddingATOM," ")+
	// 			atomEntryMap.get("subst_id").padStart(numPaddingATOM," ")+"\n";
	//
	// 		});
	//
	//
	// 	//Concatenating bond information into string
	// 	mol2FileString+= "@<TRIPOS>BOND\n";
	// 	const numPaddingBOND= formatSpacing(bondMap);
	// 	bondMap.forEach((value,key)=>{
	// 		const bondEntryMap=new Map(Object.entries(value));
	// 		mol2FileString+=bondEntryMap.get("bond_id").padStart(numPaddingBOND," ")+
	// 			bondEntryMap.get("bond_id").padStart(numPaddingBOND," ")+
	// 			bondEntryMap.get("origin_atom_id").padStart(numPaddingBOND," ")+
	// 			bondEntryMap.get("target_atom_id").padStart(numPaddingBOND," ")+
	// 			bondEntryMap.get("bond_type").padStart(numPaddingBOND," ")+"\n";
	// 		//console.log(mol2FileString);
	// 		// if(bondEntryMap.get("bond_id").length===1){
	// 		// 	mol2FileString+="     "+bondEntryMap.get("bond_id");
	// 		// }
	// 		// else if(bondEntryMap.get("bond_id").length===2){
	// 		// 	mol2FileString+="    "+bondEntryMap.get("bond_id");
	// 		// }
	// 		// else{
	// 		// 	mol2FileString+="   "+bondEntryMap.get("bond_id");
	// 		// }
	//
	// 		// if(bondEntryMap.get("origin_atom_id").length===1){
	// 		// 	mol2FileString+="     "+bondEntryMap.get("origin_atom_id");
	// 		// }
	// 		// else if(bondEntryMap.get("origin_atom_id").length===2){
	// 		// 	mol2FileString+="    "+bondEntryMap.get("origin_atom_id");
	// 		// }
	// 		// else{
	// 		// 	mol2FileString+="   "+bondEntryMap.get("origin_atom_id");
	// 		// }
	//
	// 		// if(bondEntryMap.get("target_atom_id").length===1){
	// 		// 	mol2FileString+="     "+bondEntryMap.get("target_atom_id");
	// 		// }
	// 		// else if(bondEntryMap.get("target_atom_id").length===2){
	// 		// 	mol2FileString+="    "+bondEntryMap.get("target_atom_id");
	// 		// }
	// 		// else{
	// 		// 	mol2FileString+="   "+bondEntryMap.get("target_atom_id");
	// 		// }
	//
	// 		// if(bondEntryMap.get("bond_type").length===1){
	// 		// 	mol2FileString+="    "+bondEntryMap.get("bond_type")+"\n";
	// 		// }
	// 		// else {
	// 		// 	mol2FileString+="   "+bondEntryMap.get("bond_type")+"\n";
	// 		// }
	// 	});
	// 	console.log(mol2FileString);
	// 	return mol2FileString;
	// };
	const JSONtoMOL2 = () => {
		const obj = JSON.parse(currentCompound.comp_mol2);

		// Start building the MOL2 file content
		let mol2FileString = "@<TRIPOS>MOLECULE\n";

		// MOLECULE section
		mol2FileString += `${obj.mol2.molecule.mol_name}\n`;
		mol2FileString += `${obj.mol2.molecule.num_atoms} ${obj.mol2.molecule.num_bonds} ${obj.mol2.molecule.num_subst} ${obj.mol2.molecule.num_feat} ${obj.mol2.molecule.num_sets}\n`;
		mol2FileString += `${obj.mol2.molecule.mol_type}\n`;
		mol2FileString += `${obj.mol2.molecule.charge_type}\n\n`;

		// ATOM section
		mol2FileString += "@<TRIPOS>ATOM\n";
		obj.mol2.atom.forEach(atom => {
			mol2FileString += `${atom.atom_id.toString().padStart(7)} ${atom.atom_name.padEnd(5)} ${parseFloat(atom.x).toFixed(4).padStart(10)} ${parseFloat(atom.y).toFixed(4).padStart(10)} ${parseFloat(atom.z).toFixed(4).padStart(10)} ${atom.atom_type.padEnd(8)} ${atom.subst_id.toString().padStart(3)} ${atom.subst_name.padEnd(8)} ${parseFloat(atom.charge).toFixed(4).padStart(8)}\n`;
		});

		// BOND section
		mol2FileString += "\n@<TRIPOS>BOND\n";
		obj.mol2.bond.forEach(bond => {
			mol2FileString += `${bond.bond_id.toString().padStart(5)} ${bond.origin_atom_id.toString().padStart(5)} ${bond.target_atom_id.toString().padStart(5)} ${bond.bond_type.padStart(5)}\n`;
		});

		// SUBSTRUCTURE section
		mol2FileString += "\n@<TRIPOS>SUBSTRUCTURE\n";
		obj.mol2.substructure.forEach(sub => {
			mol2FileString += `${sub}\n`;
		});

		console.log(mol2FileString);
		return mol2FileString.trim();
	};

// comment
	
	const formatSpacing = (spacingMap) =>
	{
		var largestStringLength=0;
		spacingMap.forEach((value,key)=>{
			const JSONEntryMap=new Map(Object.entries(value));
			JSONEntryMap.forEach((value,key)=>{
				largestStringLength=Math.max(largestStringLength,value.length)
			})
		
		})
		return largestStringLength+1;
	}

/*
	const downloadCSV = () => {
		const element = currentCompound.comp_properties;
		const url = window.URL.createObjectURL(
			new Blob([element])
		);
		const link = document.createElement('a');
		const filename = currentCompound.comp_material + ".csv";
		link.href = url;
		link.setAttribute(
			'download',
			filename
		);

		document.body.appendChild(link);
		link.click();
		link.parentNode.removeChild(link);
	};
*/


	const downloadCSV = () => {
		try {
			let csvData = currentCompound.comp_properties;

			// Check if comp_properties is a JSON object
			if (typeof csvData === "object") {
				// Convert JSON to CSV
				const convertJSONToCSV = (jsonData) => {
					const rows = [];
					const keys = Object.keys(jsonData[0]); // Extract column headers from the first row
					rows.push(keys.join(',')); // Create the header row

					jsonData.forEach((row) => {
						const values = keys.map((key) => row[key]); // Map values for each row
						rows.push(values.join(','));
					});

					return rows.join('\n');
				};

				// Convert JSON object to CSV string
				csvData = convertJSONToCSV(csvData);
			} else if (typeof csvData === "string") {
				// If already a string, assume it's a valid CSV or JSON string
				console.warn("comp_properties is already a string. Ensure it's in CSV format.");
			} else {
				throw new Error("comp_properties is neither JSON object nor a string.");
			}

			// Create the CSV file and download it
			const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
			const url = window.URL.createObjectURL(blob);
			const link = document.createElement("a");
			const filename = `${currentCompound.comp_material}.csv`;

			link.href = url;
			link.setAttribute("download", filename);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		} catch (error) {
			console.error("Error downloading CSV:", error);
		}
	};

	const downloadComponents = () => {
		try {
			let csvData = currentCompound.comp_components;

			// Check if comp_components is a JSON object
			if (typeof csvData === "object") {
				// Convert JSON to CSV
				const convertJSONToCSV = (jsonData) => {
					const rows = [];
					const keys = Object.keys(jsonData[0]); // Extract column headers from the first row
					rows.push(keys.join(",")); // Create the header row

					jsonData.forEach((row) => {
						const values = keys.map((key) => row[key]); // Map values for each row
						rows.push(values.join(","));
					});

					return rows.join("\n");
				};

				// Convert JSON object to CSV string
				csvData = convertJSONToCSV(csvData);
			} else if (typeof csvData === "string") {
				// If already a string, assume it's a valid CSV or JSON string
				console.warn("comp_components is already a string. Ensure it's in CSV format.");
			} else {
				throw new Error("comp_components is neither JSON object nor a string.");
			}

			// Create the CSV file and download it
			const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
			const url = window.URL.createObjectURL(blob);
			const link = document.createElement("a");
			const filename = `${currentCompound.comp_material}_components.csv`;

			link.href = url;
			link.setAttribute("download", filename);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		} catch (error) {
			console.error("Error downloading components:", error);
		}
	};



	const filteredCompound = compounds.filter(compounds=>{
		return compounds.comp_material.toLowerCase().includes(searchTitle.toLowerCase())
	});

	const handlepropertiesView = () => {
		setViewMode("properties");
	}
	const handlecomponentsView = () => {
		setViewMode("components");
	}

	return (
		<div className="container">
			<div className="row">
				<div className="col-md-8">
					<div className="input-group mb-3">
						<input
							type="text"
							className="form-control"
							placeholder="Search by name"
							value={searchTitle}
							onChange={onChangeSearchTitle}
						/>
					</div>
				</div>
			</div>
			<div className="row">

				<div className="col">
					<h4>Compounds List</h4>
					<ul className="list-group" style={{display: "block", height: "500px", overflow: "auto"}}>
						{filteredCompound && filteredCompound.map((compound, index) => (
							<strong>
								<li className={"list-group-item " + (index === currentIndex ? "active" : "")}
									onClick={() => setActiveCompound(compound, index)}
									key={compound.comp_id}>
									{compound.comp_material}
								</li>
							</strong>
						))}
					</ul>
					<button
						className="m-3 btn btn-sm btn-danger"
						onClick={removeAllCompounds}
					>Remove All
					</button>
				</div>
				<div className="col">
					{currentCompound ? (
						<div>
							<h4>Compound</h4>

							<div>
								<label>
									<strong>PubChemID:</strong>
								</label>
								<strong>{" " + currentCompound.comp_index}</strong>
							</div>
							<div>
								<label>
									<strong>Materials:</strong>
								</label>
								{" " + currentCompound.comp_material}
							</div>
							<div>
								<label>
									<strong>Notation:</strong>
								</label>
								{" " + currentCompound.comp_notation}
							</div>
							<div className="imput-group-append">
								<button
									type="button"
									className="btn btn-primary mb-2"
									onClick={() => handlepropertiesView()}
								>View Properties...
								</button>
							</div>
							<div className="input-group-append">
								<button
									type="button"
									className="btn btn-primary mb-2"
									onClick={() => handlecomponentsView()}
								>
									View Components...
								</button>
							</div>
							<div className="input-group-append">
								<button
									type="button"
									className="btn btn-outline-dark mb-2"

									onClick={() => downloadMol2()}
								>Download mol2 (.mol2)
								</button>
							</div>
							<div className="input-group-append">
								<button
									type="button"
									className="btn btn-outline-dark mb-2"
									onClick={() => downloadCSV()}
								>Download Properties (.csv)
								</button>
							</div>
							<div className="input-group-append">
								<button
									type="button"
									className="btn btn-outline-dark mb-2"
									onClick={() => downloadComponents()}
								>
									Download Components (.csv)
								</button>
							</div>
							;
							<Link
								to={"/" + currentCompound.comp_id}
								className="badge badge-primary mt-2 w-25 p-3"
							>
								Edit
							</Link>
						</div>
					) : (
						<div>
							<br/>
							<MDBCard style={{maxWidth: '22rem'}}>
								<MDBCardBody>
									<MDBCardTitle className="fw-bold">Quick Manual</MDBCardTitle>
									<MDBCardText>
										The left side is a list of compounds that are available
										on <strong>MaterialsBase</strong> database. Please click on a compound to see
										more detail or edit it. Please click on the Add button to add more compounds to
										the database.<br/><br/> Thank you !
									</MDBCardText>
								</MDBCardBody>
							</MDBCard>
						</div>
					)}
				</div>
				<div className="col">
					{viewMode === "components" ? (
						<div>
							<h4> Properties Table </h4>
							<div style={{overflow: "auto", maxHeight: "400px", width: "100%"}}>
								<table className="table table-striped table-bordered table-sm">
									<thead>
									<tr>
										<th className="th-sm fw-bold">Component_Name</th>
										<th className="th-sm fw-bold">Quantity</th>
										<th className="th-sm fw-bold">Unit</th>
									</tr>
									</thead>
									<tbody>
									{Object.entries(currentCompound.comp_components).map(([key, value], i) => (
										<tr key={i}>
											<td className="fw-bold">
												{value.ComponentName !== null && value.ComponentName !== undefined
													? value.ComponentName
													: "N/A"}
											</td>
											<td>
												{value.Quantity !== null && value.Quantity !== undefined
													? value.Quantity
													: "N/A"}
											</td>
											<td>
												{value.Unit !== null && value.Unit !== undefined
													? value.Unit
													: "N/A"}
											</td>
										</tr>
									))}
									</tbody>
								</table>
							</div>
						</div>

					) : (
						<div>
						</div>
					)}
					{viewMode === "properties" ? (
						<div>
							<h4> Properties Table </h4>
							<div style={{overflow: "auto", maxHeight: "400px", width: "100%"}}>
								<table className="table table-striped table-bordered table-sm">
									<thead>
									<tr>
										<th className="th-sm fw-bold">Property</th>
										<th className="th-sm fw-bold">Value</th>
										<th className="th-sm fw-bold">Unit</th>
									</tr>
									</thead>
									<tbody>
									{Object.entries(currentCompound.comp_properties).map(([key, value], i) => (
										<tr key={i}>
											<td className="fw-bold">
												{value.Property !== null && value.Property !== undefined
													? value.Property
													: "N/A"}
											</td>
											<td>
												{value.Value !== null && value.Value !== undefined
													? value.Value
													: "N/A"}
											</td>
											<td>
												{value.Unit !== null && value.Unit !== undefined
													? value.Unit
													: "N/A"}
											</td>
										</tr>
									))}
									</tbody>
								</table>
							</div>
						</div>

					) : (
						<div>
						</div>
					)}
				</div>
				{/*<div className="col">*/}
				{/*	*/}
				{/*</div>*/}
			</div>
		</div>
	);
};

export default CompoundsList;
