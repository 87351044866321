import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { createCompound } from "../actions/compounds";
import Papa from "papaparse"; /* csv extraction */
import { Link } from "react-router-dom";

const AddCompound = () => {
	const initialCompoundState = {
		comp_index: "",
		comp_material: "",
		comp_notation: "",
		comp_mol2: "",
		comp_components: "",
		comp_properties: "",
	};

	const [compound, setCompound] = useState(initialCompoundState);
	const [submitted, setSubmitted] = useState(false);

	const dispatch = useDispatch();
	dispatch(createCompound);

	const handleInputChange = event => {
		const { name, value } = event.target;
		setCompound({ ...compound, [name]: value });
	};

	//const handleFileUpload = event => {
	//	const { name, file } = event.target;
	//	setCompound({ ...compound, [name]: file });
	//};

	const handleCsvUpload = event => {
		const { name, file} = event.target;
		Papa.parse(document.getElementById('comp_properties').files[0], {
			header: true,
			dynamicTyping: true,
			complete: function(results) {
				setCompound({...compound, [name]: results.data});
				console.log("propertiesUpload(): ", results.data);
			}
		});
	};

	const handleCompUpload = event => {
		const { name, file} = event.target;
		Papa.parse(document.getElementById('comp_components').files[0], {
			header: true,
			dynamicTyping: true,
			complete: function(results) {
				setCompound({...compound, [name]: results.data});
				console.log("componentUpload(): ", results.data);
			}
		});
	};

	//Used to parse Mol2 file into Json Format
	const handleMol2Upload = (event) => {
		const { name } = event.target;
		const reader = new FileReader();

		reader.readAsText(event.target.files[0]);

		reader.onload = () => {
			try {
				console.log("Raw file content:", reader.result);

				// Split the .mol2 content into sections
				const sections = reader.result
					.split("@<TRIPOS>")
					.filter((section) => section.trim().length > 0);

				console.log("Split sections:", sections);

				let mol2JSON = { mol2: {} };
				sections.forEach((section) => {
					const lines = section.split("\n").filter((line) => line.trim().length > 0);
					const sectionName = lines[0].trim();
					const data = lines.slice(1);

					switch (sectionName) {
						case "MOLECULE":
							mol2JSON.mol2.molecule = parseMolecule(data);
							break;
						case "ATOM":
							mol2JSON.mol2.atom = parseAtoms(data);
							break;
						case "BOND":
							mol2JSON.mol2.bond = parseBonds(data);
							break;
						case "SUBSTRUCTURE":
							mol2JSON.mol2.substructure = parseSubstructure(data);
							break;
						default:
							console.warn(`Unknown section: ${sectionName}`);
					}
				});

				console.log("Generated JSON:", mol2JSON);

				// Update state with the JSON object
				setCompound({ ...compound, [name]: JSON.stringify(mol2JSON) });
			} catch (error) {
				console.error("Error parsing MOL2 file:", error);
			}
		};
	};

	const parseMolecule = (data) => {
		const molecule = {
			mol_name: data[0].trim(),
			num_atoms: data[1].split(/\s+/)[0],
			num_bonds: data[1].split(/\s+/)[1],
			num_subst: data[1].split(/\s+/)[2],
			num_feat: data[1].split(/\s+/)[3],
			num_sets: data[1].split(/\s+/)[4],
			mol_type: data[2].trim(),
			charge_type: data[3].trim(),
			status_bits: data[4] ? data[4].trim() : "",
			mol_comment: data[5] ? data[5].trim() : "",
		};
		return molecule;
	};

	const parseAtoms = (data) => {
		return data.map((line) => {
			const parts = line.trim().split(/\s+/);
			return {
				atom_id: parts[0],
				atom_name: parts[1],
				x: parts[2],
				y: parts[3],
				z: parts[4],
				atom_type: parts[5],
				subst_id: parts[6],
				subst_name: parts[7],
				charge: parts[8],
			};
		});
	};

	const parseBonds = (data) => {
		return data.map((line) => {
			const parts = line.trim().split(/\s+/);
			return {
				bond_id: parts[0],
				origin_atom_id: parts[1],
				target_atom_id: parts[2],
				bond_type: parts[3],
			};
		});
	};

	const parseSubstructure = (data) => {
		return data.map((line) => line.trim());
	};


	const saveCompound = () => {
		console.log("saveCompound() Clicked")
		const {
			comp_index,
			comp_material,
			comp_notation,
			comp_mol2,
			comp_components,
			comp_properties
		 } = compound;
		dispatch(createCompound(
			comp_index,
			comp_material,
			comp_notation,
			comp_mol2,
			comp_components,
			comp_properties,
		))
			.then(data => {
				console.log("createCompound(): ",data);
				setCompound({
					comp_index: data.comp_index,
					comp_material: data.comp_material,
					comp_notation: data.comp_notation,
					comp_mol2: data.comp_mol2,
					comp_components: data.comp_components,
					comp_properties: data.comp_properties,
        			});
        			setSubmitted(true);


      			})
			.catch(e => {
				console.log("createCompound error: ",e);
			});
	};

	const newCompound = () => {
		setCompound(initialCompoundState);
		setSubmitted(false);
	};



  return(
	  <div className="submit-form">
	  {submitted ? (
		  <div>
			<h4>Compound data submitted succesfully!</h4>
			<button className="btn btn-success" onClick={newCompound}>Add</button>
		  	<button className="btn btn-success text-light">
		  		<Link to={"/"}>Home</Link>
		  	</button>
		  </div>
	  ) : (
		  <div>
			  <div className="form-group">
				  <label htmlFor="comp_index">Compound Index</label>
				  <input
					  type="text"
					  className="form-control"
					  id="comp_index"
					  required
					  value={compound.comp_index}
					  onChange={handleInputChange}
					  name="comp_index"
				  />
			  </div>

			  <div className="form-group">
				  <label htmlFor="comp_material">Compound Material</label>
				  <input
					  type="text"
					  className="form-control"
					  id="comp_material"
					  required
					  value={compound.comp_material}
					  onChange={handleInputChange}
					  name="comp_material"
				  />
			  </div>

			  <div className="form-group">
				  <label htmlFor="comp_notation">Compound Notation</label>
				  <input
					  type="text"
					  className="form-control"
					  id="comp_notation"
					  required
					  value={compound.comp_notation}
					  onChange={handleInputChange}
					  name="comp_notation"
				  />
			  </div>

			  <div className="form-group">
				  <label htmlFor="comp_mol2">Compound mol2</label>
				  <input
					  type="file"
					  accept=".mol2, .txt"
					  className="form-control"
					  id="comp_mol2"
					  //value={compound.comp_mol2}
					  onChange={handleMol2Upload}
					  name="comp_mol2"
				  />
			  </div>
			  <div className="form-group">
				  <label htmlFor="comp_components">Compound component</label>
				  <input
					  type="file"
					  accept=".csv, .txt"
					  className="form-control"
					  id="comp_components"
					  //value={compound.comp_mol2}
					  onChange={handleCompUpload}
					  name="comp_components"
				  />
			  </div>
			  <div className="form-group">
				  <label htmlFor="comp_properties">Compound Properties</label>
				  <input
					  type="file"
					  accept=".csv, .txt"
					  className="form-control"
					  id="comp_properties"
					  //value={compound.comp_properties}
					  onChange={handleCsvUpload}
					  name="comp_properties"
				  />
			  </div>
			  <button onClick={saveCompound} className="btn btn-success mt-2">Submit</button>
		  </div>
	  )}
	  </div>
  );
};

export default AddCompound;
